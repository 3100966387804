<template>
<div class="home dirr">
    <div class="news"> 
        <div class="bcon d-none d-sm-flex">
            <div class="newsCon">
                <div class="titleMore">
                    <router-link :to="'/news?lang=' + $i18n.locale" class="tl">{{ $t("newsAct") }}</router-link>
                    <div class="btnCon">
                        <router-link :to="'/news/?&lang=' +$i18n.locale">
                            <v-btn width="200" height="50" class="ma-2 tl" style="align-self:center !important;" color="#BCA276">
                                <p class="btnT tl">{{ $t('allnews') }}</p>
                            </v-btn>
                        </router-link>
                    </div>
                </div>
                <div class="items">
                    <v-layout row>
                        <v-flex xs12 sm6 md8 lg8>
                            <div class="Fgroup">
                                <v-layout row>
                                    <template v-if="impoNewsRes">
                                        <v-flex xs12 sm12 md6 lg6 v-for="(i, x) in impoNewsRes.slice(0,2)" :key="i" style="padding:10px;">
                                            <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale" :class="'Fgroup' + x">
                                                <div class="img"> 
                                                    <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                                        :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px;width:100%;height:100%">
                                                    </v-img>
                                                </div>
                                                <div class="details">
                                                    <p class="tl">{{i.cat_title}}</p>
                                                    <p class="tl">{{ i.date|FormatDate }}</p>
                                                    <!-- <p class="tl">{{ i.date }}</p> -->
                                                </div>
                                                <p class="tl ta">{{i.title|subStrtitle}}</p>
                                                <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" class="ta">{{i.desc|subStrtext}}</p>
                                            </router-link>
                                        </v-flex>
                                    </template>
                                </v-layout>
                            </div>
                        </v-flex>
                        <v-flex xs12 sm6 md4 lg4>
                            <div class="Sgroup">
                                <template v-if="impoNewsRes">
                                    <router-link v-for="i in impoNewsRes.slice(2,6)" :key="i" :to="'/news/' + i.id + '?&lang=' +$i18n.locale">
                                        <div class="img" style="max-height: 121px; ">
                                             <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                                :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px;width:100%;height:100%">
                                            </v-img>
                                        </div>
                                        <div class="details">
                                            <p class="tl ta">{{i.title|subStrtitle2}}</p>
                                            <div class="cat">
                                                <p class="tl">{{i.cat_title}}</p>
                                                <p class="tl">{{ i.date|FormatDate }}</p>
                                            </div>
                                        </div>
                                    </router-link>
                                </template>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </div>
        <div class="mcon d-flex d-sm-none">
            <div class="newsCon">
                <div class="titleMore">
                    <router-link :to="'/news?lang=' + $i18n.locale" class="tl">{{ $t("newsAct") }}</router-link>
                </div>
                <div class="items">
                    <v-layout row>
                        <v-flex xs12 sm6 md8 lg8>
                            <div class="Fgroup">
                                <v-layout row>
                                    <template v-if="impoNewsRes">
                                        <v-flex xs12 sm12 md6 lg6 v-for="(i, x) in impoNewsRes.slice(0,1)" :key="i" style="padding:10px;">
                                            <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale" :class="'Fgroup' + x">
                                                <div class="img">
                                                    <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                                        :src="Url+'/art_img/art_img_main/'+i.url_image" style="border-radius: 10px; width: 100%; height: 100%;">
                                                    </v-img>
                                                </div>
                                                <div class="details">
                                                    <p class="tl">{{i.cat_title}}</p>
                                                    <p class="tl">{{ i.date|FormatDate }}</p>
                                                </div>
                                                <p class="tl ta">{{i.title|subStrtitle}}</p>
                                                <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" class="ta">{{i.desc|subStrtext}}</p>
                                            </router-link>
                                        </v-flex>
                                    </template>
                                </v-layout>
                            </div>
                        </v-flex>
                        <v-flex xs12 sm6 md4 lg4>
                            <div class="Sgroup">
                                <template v-if="impoNewsRes">
                                    <router-link v-for="i in impoNewsRes.slice(4,6)" :key="i" :to="'/news/' + i.id + '?&lang=' +$i18n.locale">
                                        <div class="img">
                                            <v-img 
                                            
                                            :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                                        :src="Url+'/art_img/thumb_new/'+i.url_image"
                                            
                                             style="border-radius: 4px;">
                                            </v-img>
                                        </div>
                                        <div class="details">
                                            <p class="tl ta">{{i.title|subStrtitle2}}</p>
                                            <div class="cat d-none d-sm-flex">
                                                <p class="tl">{{i.cat_title}}</p>
                                                <!-- <p class="tl">{{ i.time|FormatDate }}</p> -->
                                                <p class="tl">{{ i.date }}</p>
                                            </div>
                                        </div>
                                    </router-link>
                                </template>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </div>
    </div>
    <div class="photo">
        <div class="titleMore">
            <router-link :to="'/photos?lang=' + $i18n.locale" class="tl">{{ $t("photoLibrary") }} </router-link>
            <div class="btnCon d-none d-sm-flex">
                <router-link :to="'/photos/?&lang=' +$i18n.locale">
                    <v-btn width="200" height="50" class="ma-2 tl" style="align-self:center !important;" color="#BCA276">
                        <p class="btnT tl">{{ $t('allPhotos') }}</p>
                    </v-btn>
                </router-link>
            </div>
        </div>
        <div class="items">



            <swiper class="swiper dirr photos_desktop" ref="mySwiper" :options="swiperOptions3">
                <swiper-slide  class="swiper-slide" v-for="silde in 3" :key="silde">
                    <div class="row3 photos"> 
                        <template v-for="index in 3" >
                        
                            <div :class="'col column'+index" :key="index">
                                <template v-for="(i, x) in photoSlice (photosData, index, silde)">
                                    <v-img aspect-ratio="1" :lazy-src="Url+'/libraryImage/small_thumb/'+i.url_image"
                                        :src="Url+'/libraryImage/thumb/'+i.url_image" :class="'p'+x" :key="i"></v-img>
                                </template>
                            </div>

                        </template>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
            </swiper>

            <swiper class="swiper dirr photos_tab d-none" ref="mySwiper" :options="swiperOptions4">
                <swiper-slide  class="swiper-slide" v-for="silde in 5" :key="silde">
                    <div class="row3 photos"> 
                        <template v-for="index in 2" >
                        
                            <div :class="'col column'+index" :key="index">
                                <template v-for="(i, x) in photoSliceTab (photosData, index, silde)">
                                    <v-img aspect-ratio="1" :lazy-src="Url+'/libraryImage/small_thumb/'+i.url_image"
                                        :src="Url+'/libraryImage/thumb/'+i.url_image"  :class="'p'+x" :key="i"></v-img>
                                </template>
                            </div>

                        </template>
                    </div>
                </swiper-slide>
            </swiper>


            <swiper class="swiper dirr photos_mob d-none" ref="mySwiper" :options="swiperOptions4">
                <swiper-slide  class="swiper-slide" v-for="silde in 7" :key="silde">
                    <div class="row3 photos"> 
                
                        <div class="col column1" >
                            <template v-for="(i, x) in photoSliceMob (photosData, 1, silde)">
                                <v-img aspect-ratio="1" :lazy-src="Url+'/libraryImage/small_thumb/'+i.url_image"
                                        :src="Url+'/libraryImage/thumb/'+i.url_image" :class="'p'+x" :key="i"></v-img>
                            </template>
                        </div>

                    </div>
                </swiper-slide>
            </swiper>


        </div>
    </div>




    <div class="quran dirr">
        <div class="bcon d-none d-sm-flex">
            <div class="Fgroup">
                <div class="items">
                    <!-- <router-link :to="'/articles/2?lang=' + $i18n.locale" class="tl">{{ $t("wiladat") }}</router-link> -->
                    <v-layout row>
                        <v-flex xs12 sm6 md8 lg8 style="padding: 0 15px;">
                            <v-layout row>
                                    <router-link :to="'/articles/2?lang=' + $i18n.locale" class="tl stitle">{{ $t("wiladat") }}</router-link>
                                </v-layout>
                            <v-layout row>
                                <template v-for="i in impoNewsRes2" >
                                    <v-flex xs6 style="padding: 0 15px;" :Key="i">
                                        <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale">
                                            <div class="img">
                                                <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                                    :src="Url+'/art_img/thumb_new/'+i.url_image" style="height: 100%; width: 100%; border-radius:4px;">
                                                </v-img>
                                            </div>
                                            <div class="details">
                                                <div class="cat">
                                                    <p class="tl">{{ i.date|FormatDate }}</p>
                                                </div>
                                            </div>
                                            <p class="tl ta">{{i.title|subStrtitle}}</p>
                                        </router-link>
                                    </v-flex>
                                </template>
                            </v-layout>

                            <v-layout row>
                                <router-link :to="'/articles/6?lang=' + $i18n.locale" class="tl stitle">{{ $t("remoz") }}</router-link>
                            </v-layout>

                            
                            <div class="Sgroup">
                                <template v-if="impoNewsRes6">
                                    <v-layout row>
                                        <v-flex sm6 v-for="i in impoNewsRes6" :key="i" style="padding: 0 15px;">
                                            <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale" style="display: flex">
                                                <div class="simg">
                                                    <v-img  :lazy-src="Url+'/art_img/small_thumb/'+i.url_image" :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px;"></v-img>
                                                </div>
                                                <div class="sdetails">
                                                    <p class="tl ta">{{i.title|subStrtitle2}}</p>
                                                </div>
                                            </router-link>
                                        </v-flex>
                                    </v-layout>
                                </template>
                            </div>
                            
                        </v-flex>
                        
                        <v-flex xs12 sm4 md4 lg4 class="d-sm-none d-md-flex" style="padding-bottom: 5px; border-bottom: solid thick #A66531;">
                            <img class="side_icon" width="100%" src="/assets/img/side_icon.png" alt="">
                        </v-flex>
                    </v-layout>
                </div>
            </div>
            
        </div>
    </div>


    
    <div class="video">
        <div class="bcon d-none d-sm-flex">
            <div class="videoCon">
                <div class="Fitem" v-for="i in videosRes.slice(0,1)" :key="i">
                <router-link :to="'/video/' + i.id ">
                    <v-layout row>
                        <v-flex xs12 sm5 md3 lg3 style="padding: 0 10px;">
                            <router-link class="tl videoLib" to="/videos">{{ $t("videoLib") }}</router-link>
                            <p class="tx">{{ i.desc|subStrvidtext }}</p>
                            <router-link to="/videos" class="more">
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="12" fill="#BCA276"/>
                                    <path d="M16.5 11.134C17.1667 11.5189 17.1667 12.4811 16.5 12.866L10.5 16.3301C9.83333 16.715 9 16.2339 9 15.4641V8.5359C9 7.7661 9.83333 7.28497 10.5 7.66987L16.5 11.134Z" fill="white"/>
                                    </svg>
                                </span>
                                <p class="tl">{{ $t("moreVid") }}</p>
                            </router-link>
                        </v-flex>
                        <v-flex xs12 sm7 md9 lg9 style="padding: 0 10px;">
                            <div class="img">
                                <v-img :lazy-src="Url+'/imageVidoes/small_thumb/'+i.image_url"
                                    :src="Url+'/imageVidoes/img_main/'+i.image_url" style="border-radius: 4px; width: 100% !important; height: 100%;">
                                </v-img>
                                <div class="bkg"></div>
                                <div class="iconTitle">
                                    <div class="icon">
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="32" cy="32" r="32" fill="#BCA276"/>
                                        <path d="M46.5 31.134C47.1667 31.5189 47.1667 32.4811 46.5 32.866L25.5 44.9904C24.8333 45.3753 24 44.8942 24 44.1244L24 19.8756C24 19.1058 24.8333 18.6247 25.5 19.0096L46.5 31.134Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <p class="tl">{{ i.title }}</p>
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
                </router-link>
                </div>
                <div class="items">
                    <v-layout row>
                        <v-flex xs12 sm3 md3 lg3 v-for="i in videosRes.slice(1,5)" :key="i" style="padding: 0 10px;" class="item">
                            <router-link :to="'/video/' + i.id ">
                                <div class="img">
                                    <v-img :lazy-src="Url+'/imageVidoes/small_thumb/'+i.image_url"
                                        :src="Url+'/imageVidoes/slid_thumb/'+i.image_url" style="border-radius: 4px; width: 100% !important; height: 100%;">
                                    </v-img>
                                    <div class="icon">
                                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.75">
                                        <circle cx="24" cy="24" r="24" fill="#BCA276"/>
                                        <path d="M34.5 23.134C35.1667 23.5189 35.1667 24.4811 34.5 24.866L19.5 33.5263C18.8333 33.9112 18 33.4301 18 32.6603V15.3397C18 14.5699 18.8333 14.0888 19.5 14.4737L34.5 23.134Z" fill="white"/>
                                        </g>
                                        </svg>
                                    </div>
                                </div>
                                <p class="tl ta">{{i.title|subStrtitle}}</p>
                                <div class="details">
                                    <!-- <p class="tl">{{i.cat_title}}</p>
                                    <p class="tl">{{ i.time|FormatDate }}</p> -->
                                </div>
                            </router-link>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </div>

        <div class="mcon d-flex d-sm-none">
            <p class="tl videoLib">{{ $t("videoLib") }}</p>
            <div class="Fitem" v-for="i in videosRes.slice(0,1)" :key="i">
                <router-link :to="'/video/' + i.id">
                    <div class="img">
                        <!-- <v-img height="100%" :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image_url"
                            :src="'https://alkafeel.net/news/mcroped96/930/'+i.image_url" style="border-radius: 4px;">
                        </v-img> -->
                        <v-img :lazy-src="Url+'/imageVidoes/small_thumb/'+i.image_url"
                            :src="Url+'/imageVidoes/slid_thumb/'+i.image_url" style="border-radius: 4px; width: 100% !important; height: 100%;">
                        </v-img>
                        <div class="bkg"></div>
                        <div class="iconTitle">
                            <div class="icon">
                                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="32" cy="32" r="32" fill="#BCA276"/>
                                <path d="M46.5 31.134C47.1667 31.5189 47.1667 32.4811 46.5 32.866L25.5 44.9904C24.8333 45.3753 24 44.8942 24 44.1244L24 19.8756C24 19.1058 24.8333 18.6247 25.5 19.0096L46.5 31.134Z" fill="white"/>
                                </svg>
                            </div>
                            <p class="tl">{{ i.title }}</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="slider">
                <div :class="'sbtn lbtn '+ $store.state.direction"></div>
                <swiper class="swiper dirr" ref="mySwiper" :options="swiperOptions">
                    <swiper-slide v-for="i in videosRes.slice(1,5)" :key="i">
                    <router-link :to="'/video/' + i.id " class="item" >
                        <div class="img_con">
                            <div class="play">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.75">
                                    <circle cx="24" cy="24" r="24" fill="#BCA276"/>
                                    <path d="M34.5 23.134C35.1667 23.5189 35.1667 24.4811 34.5 24.866L19.5 33.5263C18.8333 33.9112 18 33.4301 18 32.6603V15.3397C18 14.5699 18.8333 14.0888 19.5 14.4737L34.5 23.134Z" fill="white"/>
                                    </g>
                                </svg>
                            </div>
                            <img :src="Url+'/imageVidoes/slid_thumb/' + i.image_url" :alt="i.title"/>
                        </div>
                        <p class="tl ta">{{i.title|subStrtitleslider}}</p>
                        <!-- <div class="details">
                            <p class="tl">{{i.cat_title}}</p>
                            <p class="tl">{{ i.time|FormatDate }}</p>
                        </div> -->
                    </router-link>
                    </swiper-slide>
                </swiper>
                <div :class="'sbtn rbtn '+ $store.state.direction"></div>
            </div>
        </div>
    </div>





    <div class="news"> 
        <div class="bcon d-none d-sm-flex">
            <div class="newsCon">
                <div class="titleMore">
                    <router-link :to="'/articles/3?lang=' + $i18n.locale" class="tl">{{ $t("munasebat") }}</router-link>
                    <router-link :to="'/articles/5?lang=' + $i18n.locale" class="tl">{{ $t("history") }}</router-link>
                </div>
                <div class="items">
                    <v-layout row>
                        <v-flex xs12 sm6 md8 lg8>
                            <div class="Fgroup">
                                <v-layout row>
                                    <template v-if="impoNewsRes3">
                                        <v-flex xs12 sm12 md6 lg6 v-for="(i, x) in impoNewsRes3.slice(0,2)" :key="i" style="padding:10px;">
                                            <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale" :class="'Fgroup' + x">
                                                <div class="img"> 
                                                    <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                                        :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px;width:100%;height:100%">
                                                    </v-img>
                                                </div>
                                                <div class="details">
                                                    <p class="tl">{{i.cat_title}}</p>
                                                    <p class="tl">{{ i.date|FormatDate }}</p>
                                                    <!-- <p class="tl">{{ i.date }}</p> -->
                                                </div>
                                                <p class="tl ta">{{i.title|subStrtitle}}</p>
                                                <!-- <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" class="ta">{{i.desc|subStrtext}}</p> -->
                                            </router-link>
                                        </v-flex>
                                    </template>
                                </v-layout>
                            </div>
                        </v-flex>
                        <v-flex xs12 sm6 md4 lg4>
                            <div class="Sgroup">
                                <template v-if="impoNewsRes5">
                                    <router-link v-for="i in impoNewsRes5" :key="i" :to="'/news/' + i.id + '?&lang=' +$i18n.locale">
                                        <div class="img" style="max-height: 121px; ">
                                             <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image" :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px;width:100%;height:100%"></v-img>
                                        </div>
                                        <div class="details">
                                            <p class="tl ta">{{i.title|subStrtitle2}}</p>
                                            <div class="cat">
                                                <p class="tl">{{i.cat_title}}</p>
                                                <p class="tl">{{ i.date|FormatDate }}</p>
                                            </div>
                                        </div>
                                    </router-link>
                                </template>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </div>
        <div class="mcon d-flex d-sm-none">
            <div class="newsCon">
                <div class="titleMore">
                    <router-link :to="'/articles/3?lang=' + $i18n.locale" class="tl">{{ $t("munasebat") }}</router-link>
                </div>
                <div class="items">
                    <v-layout row>
                        <v-flex xs12 sm6 md8 lg8>
                            <div class="Fgroup">
                                <v-layout row>
                                    <template v-if="impoNewsRes3">
                                        <v-flex xs12 sm12 md6 lg6 v-for="(i, x) in impoNewsRes3.slice(0,1)" :key="i" style="padding:10px;">
                                            <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale" :class="'Fgroup' + x">
                                                <div class="img">
                                                    <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                                        :src="Url+'/art_img/art_img_main/'+i.url_image" style="border-radius: 10px; width: 100%; height: 100%;">
                                                    </v-img>
                                                </div>
                                                <div class="details">
                                                    <p class="tl">{{i.cat_title}}</p>
                                                    <p class="tl">{{ i.date|FormatDate }}</p>
                                                </div>
                                                <p class="tl ta">{{i.title|subStrtitle}}</p>
                                                <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" class="ta">{{i.desc|subStrtext}}</p>
                                            </router-link>
                                        </v-flex>
                                    </template>
                                </v-layout>
                            </div>
                        </v-flex>
                        <v-flex xs12 sm6 md4 lg4>
                            <div class="titleMore">
                                <router-link :to="'/articles/5?lang=' + $i18n.locale" class="tl">{{ $t("history") }}</router-link>
                            </div>
                            <div class="Sgroup">
                                <template v-if="impoNewsRes5">
                                    <router-link v-for="i in impoNewsRes5" :key="i" :to="'/news/' + i.id + '?&lang=' +$i18n.locale">
                                        <div class="img">
                                            <v-img  :lazy-src="Url+'/art_img/small_thumb/'+i.url_image" :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px;"></v-img>
                                        </div>
                                        <div class="details">
                                            <p class="tl ta">{{i.title|subStrtitle2}}</p>
                                            <div class="cat d-none d-sm-flex">
                                                <p class="tl">{{i.cat_title}}</p>
                                                <p class="tl">{{ i.date }}</p>
                                            </div>
                                        </div>
                                    </router-link>
                                </template>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </div>
    </div>
</div>
</template>


<script>
import Axios from "axios";
import { Swiper, SwiperSlide, directive, Pagination, } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { Carousel3d, Slide } from 'vue-carousel-3d';
export default {
    name: "home",
    data() {
        return {
            impoNewsRes : [],
            impoNewsRes3 : [],
            impoNewsRes5 : [],
            impoNewsRes2 : [],
            impoNewsRes6 : [],
            quraansRes: [],
            quraansRes2: [],
            videosRes: [],
            libraryRes: [],
            photosData: [],
            libraryitemsRes: [],
            active: 0,
            swiperOptions: {
                // slidesPerView: "auto",
                // spaceBetween:40,
                //loop:true,
                freeMode: true,
                navigation: {
                    nextEl: ".rbtn",
                    prevEl: ".lbtn",
                },
                keyboard: true,
            },
            swiperOptions2: {
                slidesPerView: 'auto',
                spaceBetween: 25,
                keyboard: true,
                //loop: true,
                navigation: {
                    nextEl: ".rbtn2",
                    prevEl: ".lbtn2",
                },
            },
            // photos desktop
            swiperOptions3: { 
                slidesPerView: 'auto',
                spaceBetween: 10,
                keyboard: true,
                pagination: {el: '.swiper-pagination', clickable: true,}
            },
            // photos mobile
            swiperOptions4: { 
                slidesPerView: 'auto',
                spaceBetween: 10,
                keyboard: true,
            },
        }
    },
    components: {
        Swiper, SwiperSlide, Pagination,
        Carousel3d, Slide,
    },
    directives: {
        swiper: directive,
    },
    computed: {
        swiper() {return this.$refs.mySwiper.$swiper},
    },
    mounted() {
    }, 
    created () {
        this.getimpoNews();
    },
    methods:{
        getimpoNews() {
            Axios.get("articles/GetByCat/1/6", {
            })
            .then(res => {
                this.impoNewsRes = res.data.data;
                this.getNews3();
                this.getNews5();
                this.getNews2();
                this.getNews6();
                this.getvideos();
                this.getPhotos();
            })
        },
        getNews3 () {
            Axios.get("articles/GetByCat/3/3", {
            })
            .then(res => {
                this.impoNewsRes3 = res.data.data;
            })
        },
        getNews5 () {
            Axios.get("articles/GetByCat/5/3", {
            })
            .then(res => {
                this.impoNewsRes5 = res.data.data;
            })
        },
        getNews2 () {
            Axios.get("articles/GetByCat/2/2", {
            })
            .then(res => {
                this.impoNewsRes2  = res.data.data;
            })
        },
        getNews6 () {
            Axios.get("articles/GetByCat/6/2", {
            })
            .then(res => {
                this.impoNewsRes6 = res.data.data;
            })
        },
        // getquraans () {
        //     Axios.get("articles/GetByCat/2/4", {
        //     })
        //     .then(res => {
        //         this.quraansRes = res.data.data;
        //         //console.log(res.data);
        //     })
        //     .catch(() => {
        //     });
        //     Axios.get("articles/GetByCat/3/2", {
        //     })
        //     .then(res => {
        //         this.quraansRes2 = res.data.data;
        //         //console.log(res.data);
        //     })
        //     .catch(() => {
        //     });
        // },
        getvideos () {
            Axios.get("videos/getlimit/5", {
            })
            .then(res => {
                this.videosRes = res.data.data;
                //console.log(res.data);
            })
            .catch(() => {
            });
        },
        getlibrary () {
            Axios.get("category_books", {
            })
            .then(res => {
                this.libraryRes = res.data.data;
                this.showlibrary (res.data.data[0].category_id, 0);
                //console.log(res.data.data);
            })
            .catch(() => {
            });
        },
        showlibrary (x, n) {
            this.active = n;
            Axios.get("books/getByCat/"+x+"/6", {})
            .then(res => {
                this.libraryitemsRes = res.data.data;
            })
            .catch(() => {
            });
        },
        getPhotos() {
            // 21 items api
            // Axios.get("https://alkafeel.net/main_bg/data/photo_main.json")
            Axios.get("libraryImage")
            .then(d => {this.photosData = d.data.data;})
        },
        photoSlice (data, index, silde) {
            let start,end,step;
            step = (silde-1)*7;
            if (index === 1) {
                start = step+0;
                end = start+3;
            } else {
                start = step+(index*2)-1;
                end = start+2;
            }
            return data.slice(start, end)
        },
        photoSliceTab (data, index, silde) {
            let start,end,step;
            step = (silde-1)*4;
            start = step+(index*2)-2;
            end = start+2;
            return data.slice(start, end)
        },
        photoSliceMob (data, index, silde) {
            let start,end,step;
            step = (silde-1)*3;
            start = step+(index*2)-2;
            end = start+3;
            return data.slice(start, end)
        },
    },
    filters: {
        subStrtext: function (string) {if (string != null) {return string.substring(0, 150) + '...';}},
        subStrvidtext: function (string) {if (string != null) {return string.substring(0, 200) + '...';}},
        subStrtitle: function (string) {if (string != null) {return string.substring(0, 80) + '...';}},
        subStrtitlelibrary: function (string) {if (string != null) {return string.substring(0, 35) + '...';}},
        subStrtitleslider: function (string) {if (string != null) {return string.substring(0, 70) + '...';}},
        subStrtitle2: function (string) {if (string != null) {return string.substring(0, 70) + '...';}},
        FormatDate: function (unixTime) {
            let d = new Date(unixTime*1000);
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            return (`${da}-${mo}-${ye}`);
        },    
    },
}
</script>
<style>
    .btn_for {
        background-color: #BCA276;
    }
</style>

<style scoped>
    .quran .Sgroup {
        width: 100%;
        margin: 0;
    }
    .sdetails {
        flex: 1;
        padding-right: 15px;
    }
    .simg {
        flex: 1;
        max-width: 110px;
        max-height: 30px;
        height: auto;
        width: 100%;
        border-radius: 10px;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .stitle {
        text-decoration: none;
        font-size: 30px;
        letter-spacing: normal;
        margin: 15px 0;
        display: block;
        color: #000;
        padding: 0 15px;
    }
    .home .quran .titleMore {
        padding: 0 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .home .quran .titleMore a {
        color: #000000;
        text-decoration: none;
        font-size: 38px;
        letter-spacing: normal;
        margin: 0;
    }
    .row3 {
        display: flex;
        flex-wrap: wrap;
        padding: 50px 4px;
    }


    .photos_desktop .photos .column1 .p0 {
        width:41%; height:257px; margin: 0;
    }
    .photos_desktop .photos .column1 .p1 {
        width:56%; height:257px; margin: 0 10px 0 0;
    }
    .photos_desktop .photos .column1 .p2 {
        width:100%; height:333px; margin: 10px 0 0 0;
    }
    .photos_desktop .photos .column2 .p0 {
        width:100%; height:360px; margin-bottom: 10px;
    }
    .photos_desktop .photos .column2 .p1 {
        width:100%; height:230px; 
    }
    .photos_desktop .photos .column3 .p0 {
        width:100%; height:223px; margin-bottom: 10px;
    }
    .photos_desktop .photos .column3 .p1 {
        width:100%; height:367px; 
    }


    /* tab */
    .photos_tab .photos .column1 .p0 {
        width:100%; height:360px; margin-bottom: 10px;
    }
    .photos_tab .photos .column1 .p1 {
        width:100%; height:230px; 
    }
    .photos_tab .photos .column2 .p0 {
        width:100%; height:223px; margin-bottom: 10px;
    }
    .photos_tab .photos .column2 .p1 {
        width:100%; height:367px; 
    }
    .photos_tab .column1, .photos_tab .column2 {
        flex: 48%;
        max-width: 48%;
    }

    /* Moble */
    .photos_mob .photos .column1 .p0 {
        width:41%; height:159px; margin: 0;
    }
    .photos_mob .photos .column1 .p1 {
        width:56%; height:159px; margin: 0 10px 0 0;
    }
    .photos_mob .photos .column1 .p2 {
        width:100%; height:207px; margin: 10px 0 0 0;
    }


    .photos .col {
        margin:5px; padding:0
    }
    .photos_desktop .photos .column1 {
        flex: 50%;
        max-width: 50%;
        display: flex;
        flex-wrap: wrap;
    }

    .photos_desktop .photos .column2 {
        flex: 22%;
        max-width: 22%;
    }

    .photos_desktop .photos .column3 {
        flex: 25%;
        max-width: 25%;
    }

    .photos .col img {
        vertical-align: middle;
    }

    @media (max-width: 1083px) {
        .photos_desktop {
            display: none;
        }
        .photos_tab {
            display: block!important;
        }
    }

    @media (max-width: 578px) {
        .photos_tab {
            display: none!important;
        }
        .photos_mob {
            display: block!important;
        }
        .photos_mob .photos .column1 {
            flex: 100%;
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
    }
</style>